import React from 'react'
import Seo from "../../components/seo/index";
import Layout from "./layout/index";
import RacePlaces from './components/racePlaces'

const RaceLocations = () => {
  return (
    <div>
    <Seo title="Green Heartfulness Run 2024" />
    <Layout isSticky>
      <RacePlaces />
    </Layout>
  </div>
  )
}

export default RaceLocations